@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
}